import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

import type { IMediaRecorder } from 'extendable-media-recorder';

const ASSISTANT_URL = process.env.ASSISTANT_URL;
const TIME_TO_SEND_DATA = 500 as const;

let receivedAudioChunks: string[] = [];
let recordedAudioChunks: Blob[] = [];

const mic_icon =
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mic"><path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"/><path d="M19 10v2a7 7 0 0 1-14 0v-2"/><line x1="12" x2="12" y1="19" y2="22"/></svg>';

const RECORDER_CONSTRAINTS = {
  mimeType: 'audio/wav',
  sampleRate: 16000,
  bitDepth: 16,
  channels: 1,
};

const OUTPUT_CONSTRAINTS = {
  sampleRate: 16000,
};

let button: HTMLButtonElement | undefined;
let mediaRecorder: IMediaRecorder | undefined;
let webSocket: WebSocket | undefined;
let isRecording = false;

let ctx: AudioContext;
let sourceQueue: AudioBufferSourceNode[] = [];
let nextPlayTime = 0;
const rampTime = 0.075;

function getLanguageFromQueryString() {
  return new URLSearchParams(window.location.search).get('lang') || 'es';
}

function getSynthesizerFromQueryString() {
  return new URLSearchParams(window.location.search).get('synthesizer') || 'google-cloud';
}

function isSocketOpen() {
  return webSocket?.readyState === WebSocket.OPEN;
}

function sendAudioEndedEvent() {
  console.log('audio ended');
  if (isSocketOpen()) {
    webSocket?.send(JSON.stringify({ event: 'audio-end', data: {} }));
    webSocket?.send(JSON.stringify({ event: 'audio-start', data: {} }));
  }
}

function sendInterruptEvent() {
  console.log('interrupting');
  if (isSocketOpen()) {
    webSocket?.send(JSON.stringify({ event: 'interrupt', data: {} }));
  }
}

const MIN_DECIBELS = -45;
const MAX_SILENCE_TIME = 1000;
let currentTime = 0;
let lastSoundDetectedTime = 0;
let soundDetected = false;

async function createClient(inputStream: MediaStream) {
  const lang = getLanguageFromQueryString();
  const synthesizer = getSynthesizerFromQueryString();

  webSocket = new WebSocket(`${ASSISTANT_URL}?adapter=web&lang=${lang}&synthesizer=${synthesizer}`);

  await register(await connect());

  ctx = new AudioContext({ sampleRate: OUTPUT_CONSTRAINTS.sampleRate });

  const audioContext = new AudioContext({ sampleRate: RECORDER_CONSTRAINTS.sampleRate });
  const source = audioContext.createMediaStreamSource(inputStream);

  const mediaStreamAudioDestinationNode = new MediaStreamAudioDestinationNode(audioContext, {
    channelCount: RECORDER_CONSTRAINTS.channels,
  });
  const { stream } = mediaStreamAudioDestinationNode;
  Object.defineProperty(stream.getAudioTracks()[0], 'getSettings', {
    value: () => ({
      channelCount: RECORDER_CONSTRAINTS.channels,
      sampleRate: RECORDER_CONSTRAINTS.sampleRate,
    }),
  });

  source.connect(mediaStreamAudioDestinationNode);

  const analyser = audioContext.createAnalyser();
  analyser.minDecibels = MIN_DECIBELS;
  source.connect(analyser);

  const bufferLength = analyser.frequencyBinCount;
  const domainData = new Uint8Array(bufferLength);

  const detectSound = () => {
    analyser.getByteFrequencyData(domainData);

    currentTime = Date.now();

    for (let i = 0; i < bufferLength; i++) {
      const value = domainData[i] || 0;
      const hasSound = value > 0;

      if (!soundDetected && hasSound) {
        soundDetected = true;
        sendInterruptEvent();
      }

      if (hasSound) {
        lastSoundDetectedTime = Date.now();
      }

      const totalSilenceTime = currentTime - lastSoundDetectedTime;
      if (soundDetected && totalSilenceTime >= MAX_SILENCE_TIME) {
        console.log('totalSilenceTime', totalSilenceTime);
        console.log('silence detected for too long');
        lastSoundDetectedTime = 0;
        soundDetected = false;
        sendAudioEndedEvent();
      }
    }

    window.requestAnimationFrame(detectSound);
  };

  window.requestAnimationFrame(detectSound);

  mediaRecorder = new MediaRecorder(stream, {
    mimeType: RECORDER_CONSTRAINTS.mimeType,
    audioBitsPerSecond:
      RECORDER_CONSTRAINTS.sampleRate *
      RECORDER_CONSTRAINTS.bitDepth *
      RECORDER_CONSTRAINTS.channels,
  });
  mediaRecorder.start(TIME_TO_SEND_DATA);
  mediaRecorder.addEventListener('dataavailable', event => {
    console.log('recording chunk received');
    if (isSocketOpen()) {
      accumulateOrSendRecordedData(event.data);
    }
  });
  mediaRecorder.addEventListener('stop', () => {
    inputStream.getTracks().forEach(track => track.stop());
  });
  return webSocket;
}

function accumulateOrSendRecordedData(chunk: Blob) {
  if (!soundDetected) {
    // only keep the last 3 chunks in memory
    recordedAudioChunks.push(chunk);
    recordedAudioChunks = recordedAudioChunks.slice(-2);
    return;
  }

  if (soundDetected && recordedAudioChunks.length > 1) {
    const previousRecordedChunks = new Blob(recordedAudioChunks, {
      type: RECORDER_CONSTRAINTS.mimeType,
    });
    const audio = new Blob([previousRecordedChunks, chunk], {
      type: RECORDER_CONSTRAINTS.mimeType,
    });
    webSocket?.send(audio);
    recordedAudioChunks = [];
    return;
  }

  webSocket?.send(chunk);
}

async function createMicrophoneStream() {
  if (navigator.mediaDevices.getUserMedia) {
    console.log('getUserMedia supported.');
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    return stream;
  }
}

async function start() {
  if (isRecording && mediaRecorder && webSocket) {
    mediaRecorder.stop();
    webSocket.close();
    return;
  }
  const inputStream = await createMicrophoneStream();
  if (!inputStream) {
    throw new Error('No input stream');
  }

  const client = await createClient(inputStream);

  client.onmessage = event => {
    const msg = JSON.parse(event.data);
    if (msg.event === 'audio') {
      receivedAudioChunks.push(msg.data);
      decode();
    }

    if (msg.event === 'clear') {
      interruptAudio();
    }
  };

  client.onopen = () => {
    if (!button) return;
    button.setAttribute('data-recording', 'true');
    isRecording = true;
  };

  client.onclose = () => {
    if (!button) return;
    button.setAttribute('data-recording', 'false');
    isRecording = false;
  };
}

window.onload = () => {
  button = document.createElement('button');
  button.id = 'tolvia-demo-button';
  button.innerHTML = mic_icon;
  button.setAttribute('data-recording', 'false');
  button.onclick = start;
  const demoContainer = document.getElementById('tolvia-demo');
  if (!demoContainer) {
    throw new Error('No container');
  }
  demoContainer.appendChild(button);
};

function base64ToArrayBuffer(base64: string) {
  const binaryData = atob(base64);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryData.length; i++) uint8Array[i] = binaryData.charCodeAt(i);

  return { arrayBuffer, length: uint8Array.length };
}

function createAudioBuffer(arrayBuffer: ArrayBuffer, length: number) {
  const data = new DataView(arrayBuffer);

  if (!ctx) throw new Error('No audio context');

  const audioBuffer = ctx.createBuffer(1, length / 2, OUTPUT_CONSTRAINTS.sampleRate);
  const channelData = audioBuffer.getChannelData(0);

  for (let i = 0; i < data.byteLength; i += 2) {
    const sample = data.getInt16(i, true);
    channelData[i / 2] = sample / 32768;
  }

  return audioBuffer;
}

function decode() {
  const base64 = receivedAudioChunks.shift();

  if (!base64) return;
  if (!ctx) throw new Error('No audio context');

  const { arrayBuffer, length } = base64ToArrayBuffer(base64);
  const audioBuffer = createAudioBuffer(arrayBuffer, length);

  const source = ctx.createBufferSource();
  source.buffer = audioBuffer;

  const gainNode = ctx.createGain();
  gainNode.connect(ctx.destination);
  source.connect(gainNode);

  sourceQueue.push(source);

  if (nextPlayTime < ctx.currentTime) nextPlayTime = ctx.currentTime;

  gainNode.gain.cancelScheduledValues(0);
  gainNode.gain.setValueAtTime(0, nextPlayTime);
  gainNode.gain.linearRampToValueAtTime(1, nextPlayTime + rampTime);

  schedulePlaySource(source);

  nextPlayTime += audioBuffer.duration;

  decode();
}

function schedulePlaySource(source: AudioBufferSourceNode) {
  source.start(nextPlayTime);
  source.addEventListener('ended', () => sourceEnded());
}

function interruptAudio() {
  nextPlayTime = 0;
  console.log('interrupting audio');
  receivedAudioChunks = [];
  sourceQueue.forEach(source => source.stop());
  sourceQueue = [];
}

function sourceEnded() {
  sourceQueue.shift();
  if (!sourceQueue.length) {
    console.log('Audio finished playing');
  }
}
